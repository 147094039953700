import { defineStore } from 'pinia'
import type { AlertBadgeProps } from '~common/types'

export const useGlobalStore = defineStore({
  id: 'global',
  state: () => ({
    moleculeAlertBadge: null as AlertBadgeProps | null,
  }),
  actions: {
    setMoleculeAlertBadge(data: AlertBadgeProps) {
      this.moleculeAlertBadge = data
    },
    clearMoleculeAlertBadge() {
      this.moleculeAlertBadge = null
    },
  },
})
